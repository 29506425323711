import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'


const data = [
    {
        id: 1, icon: <SiAdobexd/>, title: 'UI/UX Design', desc: "J'utilise les technologie de pointe pour être en mesure d'avoir les standarts de l'industrie."
    },
    {
        id: 2, icon: <RiReactjsLine/>, title: 'Dévelopment Frontend', desc: "Peu importe le média votre site sera compatible et il aura une allure élégante."
    },
    {
        id: 3, icon: <FaServer/>, title: 'Dévelopment Backend', desc: "Votre site sera robuste et sécuritaire. Tout sera mis en place pour avoir un projet 100% opérationel."
    },
    {
        id: 4, icon: <AiFillAppstore/>, title: 'Application Native', desc: 'Votre site aura une application Native sois Ios ou Android qui permettera de naviguer dans votre projet.'
    }
]


export default data