import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {BiHappyHeartEyes} from 'react-icons/bi'

const data = [
    {id: 1, icon: <FaAward/>, title: 'Expérience', desc: "Plusieurs années dans le domainede l'informatique"},
    {id: 2, icon: <TbBooks/>, title: 'Projets', desc: "Design graphique - commerce électronique"},
    {id: 3, icon: <BiHappyHeartEyes/>, title: 'Clients', desc: "Toujours + de clients satisfaits"},
]

export default data;